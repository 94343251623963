import fetchData, { APIURL, deleleData, getData, postData } from "../fetch";


/**
 * 
 * @typedef {{
 *  id: string 
 *  event_id: string 
 *  event: string 
 *  product: string 
 *  dimensions: string 
 *  qty: number 
 *  no_days: number
 *  total_amount: number
 *  payment_amount: number
 *  balance: number
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string 
 * }} EventProductObject
 */

/**
 * 
 * @typedef {{
 *  id: string 
 *  reference: string
 *  client_id: string 
 *  client: string 
 *  event_date: string 
 *  end_date: string 
 *  location: string 
 *  classification: string
 *  category: string 
 *  colour: string 
 *  no_products: number
 *  amount: number
 *  payment_amount: number
 *  balance: number
 *  added_by: string 
 *  updated_by: string 
 *  date_added: string 
 *  date_updated: string 
 *  client: import("./clients").ClientObject
 *  products: EventProductObject[]
 *  notes: NoteObject[]
 *  event_dates: DateObject[]
 * }} EventObject
 */


/**
 * 
 * @typedef {{
 *  id: string 
 *  event_id: string
 *  content: string 
 *  added_by: string 
 *  date_added: string 
 *  display_name: string
 * }} NoteObject
 */


/**
 * 
 * @typedef {{
 *  id: string 
 *  event_id: string
 *  start: string 
 *  end: string 
 *  date_added: string 
 *  added_by: string
 *  client: string
 *  event: string
 *  colour: string
 * }} DateObject
 */


/**
 * 
 * @param {EventObject} data 
 * @returns {Promise<{code: number, message: string, event: EventObject}>}
 */
const createEvent = data => {
    return postData({
        url: `${APIURL}/events`,
        data
    })
}

/**
 * 
 * @param {EventObject} data 
 * @param {string} id
 * @returns {Promise<{code: number, message: string, event: EventObject}>}
 */
const updateEvent = (data, id) => {
    return postData({
        url: `${APIURL}/events/${id}`,
        data
    })
}

/**
 * 
 * @param {boolean} getAll If to get the expanded list  
 * @returns {Promise<{code: number, message: string, events: EventObject[], event_dates: DateObject[]}>}
 */
const getEvents = (getAll = false, conditions = null) => {

    const obj = { url: `${APIURL}/events`, data: {} }

    if (getAll) obj.data.expanded = 1;
    if (conditions) obj.data = { ...obj.data, ...conditions };

    return getData(obj)

}


/**
 * 
 * @param {string} id 
 * @param {string[]|null} includes 
 * @returns  {Promise<{code: number, message: string, event: EventObject}>}
 */
const getEvent = (id, includes = null) => {

    const obj = { url: `${APIURL}/events/${id}` };

    if (includes) obj.data = { include: includes.join(",") };

    return getData(obj);
}
/**
 * 
 * @param {string} start_date 
 * @param {string} end_date 
 * @returns  {Promise<{code: number, message: string, products: {event_date: string, reference: string, product: string, location: string, dimensions: string}[]}>}
 */
const getEventProducts = (start_date, end_date) => {

    const obj = { url: `${APIURL}/events/products`, data: { start_date, end_date } };

    return getData(obj);
}




/**
 * Delete an event
 * @param {string} id ID of the event
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteEvent = id => deleleData({ url: `${APIURL}/events/${id}` });

/**
 * Create notes
 * @param {string} id 
 * @param {string} content of the note 
 * @returns  {Promise<{code: number, message: string, note: NoteObject}>}
 */
const createNote = (id, content) => {

    const obj = { url: `${APIURL}/notes`, data: { content, event_id: id } };

    return postData(obj);
}


/**
 * Delete a note
 * @param {string} id ID of the note
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteNote = id => deleleData({ url: `${APIURL}/notes/${id}` });


/**
 * Create notes
 * @param {string} id 
 * @param {DateObject} data of the note 
 * @returns  {Promise<{code: number, message: string, event_date: DateObject}>}
 */
const createDates = (id, data) => {

    const obj = { url: `${APIURL}/events/${id}/dates`, data };
    return postData(obj);
}

/**
 * Delete a note
 * @param {string} id ID of the note
 * @returns  {Promise<{code: number, message: string}>}
 */
const deleteDate = (id, dateid) => fetchData({ url: `${APIURL}/events/${id}/dates/${dateid}`, method: "DELETE" });


/**
 * 
 * @param {import("./payroll").StaffPaymentObject} data 
 * @returns {Promise<{code: number, message: string, payment: import("./payroll").StaffPaymentObject}>}
 */
const createLabour = (data) => fetchData({ url: `${APIURL}/events-labour`, data, method: "POST" });


/**
 * @param {string} id
 * @param {import("./payroll").StaffPaymentObject} data 
 * @returns {Promise<{code: number, message: string, payment: import("./payroll").StaffPaymentObject}>}
 */
const updateLabour = (data, id) => fetchData({ url: `${APIURL}/events-labour/${id}`, data, method: "POST" });

/**
 * 
 * @param {string} id  ID of the event
 * @returns {Promise<{code: number, message: string, payments: import("./payroll").StaffPaymentObject}>}
 */
const getLabour = (id) => fetchData({ url: `${APIURL}/events-labour/${id}` })



/**
 * 
 * @param {string} id 
 * @param {"draft"|"awaiting-approval"|"approved"} status 
 * @returns  {Promise<{code: number, message: string, event: EventObject}>}
 */
const changeEventStatus = (id, status) => postData({ url: `${APIURL}/events/${id}/status/${status}` });



export {
    createEvent,
    deleteEvent,
    getEvent,
    getEvents,
    updateEvent,
    createNote,
    deleteNote,
    createDates,
    deleteDate,
    getEventProducts,
    createLabour,
    getLabour,
    updateLabour,
    changeEventStatus
}