
import { useEffect, useState } from "react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Nav from '../../components/breadcrumb';
import { ErrorLoading, PageLoading } from "../../components/loading";
import PageHeader, { Title, ActionBar, ActionButton as Btn, ButtonToolbar } from '../../components/pageheader';
import { deleteSchedule, getSchedule } from "../../resources/api/schedules";
import moment from "moment";
import { UCWords, sortFunction } from "../../components/resources";
import { Table } from "react-bootstrap";
import { APIURL } from "../../resources/fetch";
import { EditSchedule } from "./schedule-manipulation";
import { ConfirmAction } from "../../components/prompt";
import { errorAlert, infoAlert, successAlert } from "../../components/toastr";


/**
 * 
 * @param {Object} props
 * @param {import("../../resources/api/schedules").ScheduleObject} props.details
 */
const ViewDetails = ({ details }) => {

    const navigate = useNavigate();

    const nav_items = [
        { title: 'Schedules', href: "/app/schedules" },
        { title: moment(details.schedule_date).format("ddd DD/MM/YYYY") }
    ]

    const handleDelete = () => {
        infoAlert("Deleting the schedule");
        deleteSchedule(details.id)
            .then(({ message }) => {
                successAlert(message);
                navigate(`/app/schedules`);
            })
            .catch(errorAlert)
    }


    return (
        <>
            <Nav items={nav_items} />

            <PageHeader maxWidth="1000">
                <Title>{moment(details.schedule_date).format("dddd DD MMMM YYYY")}</Title>
                <ActionBar>
                    <ButtonToolbar>
                        <Btn title="Download Schedule" href={`${APIURL}/schedules/${details.id}/pdf`} isExternalLink>
                            <i className="fas fa-file-pdf" />
                        </Btn>
                        <ConfirmAction
                            as={Btn}
                            confirmationText="Are you sure you want to delete this schedule? This action cannot be reversed."
                            handleAction={handleDelete}
                            title="Delete Schedule"
                        >
                            <i className="fas fa-trash-alt" />
                        </ConfirmAction>
                    </ButtonToolbar>
                    <ButtonToolbar>
                        <Btn href={`/app/schedules/${details.id}/edit`} title="Edit Details">
                            <i className="fas fa-pencil-alt" />
                        </Btn>
                        <Btn href="/app/schedules/new" title="New Schedule">
                            <i className="fas fa-plus-circle" />
                        </Btn>
                    </ButtonToolbar>
                </ActionBar>
            </PageHeader>

            <div className="max-1000">
                <h4>Schedule Details</h4>
                <dl>
                    <dt>Status</dt>
                    <dd>{UCWords(details.status.replace(/-/g, " "))}</dd>
                    <dt>No Deliveries</dt>
                    <dd>{details.no_events}</dd>
                    <dt>No Vehicles</dt>
                    <dd>{details.no_vehicles}</dd>
                </dl>

                {/* <ViewEvents events={details.events} /> */}

                <Table>
                    <thead>
                        <tr>
                            <th>Event</th>
                            <th>Event Date</th>
                            <th>Location</th>
                            <th>Action</th>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>ETA</th>
                            <th>Vehicle</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.products.map(p => (
                            <tr key={p.id}>
                                <td>{`${p.client_title} - ${p.event_reference}`}</td>
                                <td>{moment(p.event_date).format("DD MMM YYYY")}</td>
                                <td>{p.event_location}</td>
                                <td>{UCWords(p.action)}</td>
                                <td>{p.product}</td>
                                <td>{p.product_quantity}</td>
                                <td>{moment(p.eta, "HH:mm:ss").format("HH:mm")}</td>
                                <td>{p.reg_no}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

            </div>

        </>
    )

}



const ScheduleDetails = () => {

    const { scheduleid } = useParams();
    const [details, setDetails] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [error, setError] = useState();


    useEffect(() => {

        setLoaded(false);
        setError(null);

        // const sortFn = (a, b) => sortFunction(a, b, 'event_date', "asc");

        getSchedule(scheduleid, ["products"])
            .then(({ schedule }) => {
                schedule.products.sort((a, b) => sortFunction(a, b, "eta"));
                setDetails({ ...schedule });
            })
            .catch(e => setError(e))
            .finally(() => setLoaded(true));

    }, [scheduleid])

    //load events details

    if (!isLoaded) {
        return <PageLoading />
    }

    if (error) return <ErrorLoading>{error}</ErrorLoading>;

    return (
        <Routes>
            <Route path="/edit" element={<EditSchedule details={details} />} />
            <Route path="/" element={<ViewDetails details={details} />} />
        </Routes>
    )

}


export default ScheduleDetails;